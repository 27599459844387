import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

import * as events from '../lib/events';
import Dispatch from '../core/Dispatch';

export default (el, props) => {
    const $el = $(el);

    const $mobileMenuBg = $el.find('[data-mobile-menu-bg]');
    const $mobileMenuLogo = $el.find('[data-mobile-menu-logo]');
    const $mobileMenuWrapper = $el.find('[data-mobile-menu-wrapper]');

    const $mainMenuPanel = $el.find('[data-main-menu-panel]');
    const $panel = $el.find('[data-mobile-submenu-panel]');
    const $backBtn = $el.find('[data-back-btn]');
    const $languageToggle = $el.find('[data-mobile-language-toggle]');
    const $languagePanel = $el.find('[data-mobile-language-panel]');

    let $currentPanel = null;
    
    let isOpen = false;

    const init = () => {
        $mainMenuPanel.on('click', '[data-mobile-panel-trigger]', onPanelTrigger);
        $backBtn.on('click', onBackClick);
        $languageToggle.on('click', onLanguageClick);
        $el.on('click', '[data-close-btn]', onClose);
        $('body').on('keyup', onBodyKeyUp);
        
        $mobileMenuBg.on('click', onBgClick);
        
        Dispatch.on(events.OPEN_MOBILE_MENU, onOpen);
    };

    const destroy = () => {
        $mainMenuPanel.off('click');
        $backBtn.off('click');
        $languageToggle.off('click');
        $el.off('click')
        $('body').off('keyup', onBodyKeyUp);
        
        Dispatch.off(events.OPEN_MOBILE_MENU, onOpen);
    };
    
    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            onClose();
        }
    };
    
    const onBgClick = e => {
        if (!isOpen) {
            return;
        }
        
        onClose();
    };

    const onOpen = () => {
        $el.css({ display: 'block' });

        gsap.set($mobileMenuBg.get(0), { opacity: 0 });
        gsap.set($mobileMenuLogo.get(0), { opacity: 0 });
        gsap.set($mobileMenuWrapper.get(0), { x: '100%' });

        gsap.to($mobileMenuBg.get(0), { duration: 0.3, opacity: 1 });
        gsap.to($mobileMenuLogo.get(0), { duration: 0.3, opacity: 1 });
        gsap.to($mobileMenuWrapper.get(0), { duration: 0.6, x: '0%', ease: 'quint.out' });
        
        isOpen = true;
    };

    const onClose = () => {
        gsap.to($mobileMenuBg.get(0), { duration: 0.2, opacity: 0 });
        gsap.to($mobileMenuLogo.get(0), { duration: 0.2, opacity: 0 });
        gsap.to($mobileMenuWrapper.get(0), {
            duration: 0.4, x: '100%', ease: 'quint.out', onComplete: () => {
                $el.css({ display: 'none' });
            }
        });

        isOpen = false;
        
        Dispatch.emit(events.CLOSE_MOBILE_MENU);
    };

    const onPanelTrigger = e => {
        e.preventDefault();
        const $trigger = $(e.triggerTarget);
        const $panel = $el.find($trigger.attr('href'));

        if ($panel.length > 0) {
            gsap.to($mainMenuPanel.get(0), {
                duration: 0.3, ease: 'quart.in', opacity: 0, x: -50, onComplete: () => {
                    $mainMenuPanel.css({ display: 'none' });
                    $panel.css({ display: 'block' });
                    gsap.set($panel.get(0), { display: 'block', x: 50, opacity: 0 });
                    gsap.to($panel.get(0), { delay: 0.2, duration: 0.7, x: 0, opacity: 1, ease: 'quint.out' });
                }
            });

            $currentPanel = $panel;

            $backBtn.find('span').text($trigger.text());
            $backBtn.css({ display: 'flex', opacity: 0 });
            gsap.to($backBtn.get(0), { delay: 0.4, duration: 0.4, opacity: 1 })
        }
    };

    const onBackClick = () => {
        if ($currentPanel !== null) {
            const $lastPanel = $currentPanel;

            gsap.to($currentPanel.get(0), {
                duration: 0.3, ease: 'quart.in', opacity: 0, x: 50, onComplete: () => {
                    $lastPanel.css({ display: 'none' });
                    $mainMenuPanel.css({ display: '' });
                    gsap.to($mainMenuPanel.get(0), { delay: 0.2, duration: 0.7, x: 0, opacity: 1, ease: 'quint.out' });
                }
            })

            gsap.to($backBtn.get(0), {
                duration: 0.2, opacity: 0, onComplete: () => {
                    $backBtn.css({ display: 'none' });
                }
            })

            $currentPanel = null;
        }
    };

    const onLanguageClick = () => {
        if ($languageToggle.attr('aria-expanded') === 'false') {
            gsap.set($languagePanel.get(0), { display: 'block', opacity: 0, y: 20 });
            gsap.to($languagePanel.get(0), { duration: 0.3, opacity: 1, y: 0, ease: 'quart.out' });
            $languageToggle.attr('aria-expanded', 'true');
        } else {
            gsap.to($languagePanel.get(0), { duration: 0.15, opacity: 0, y: 20, onComplete: () => {
                    $languagePanel.css({ display: 'none', height: '' });
                }
            });

            $languageToggle.attr('aria-expanded', 'false');
        }
    };

    return {
        init,
        destroy
    };
};

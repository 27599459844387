import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

import { FILTER_REMOVE_TERM } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');
    const { resetUrl } = props;

    let activeFilterPanel = null;

    const init = () => {
        $el.on('click', '[data-filter-toggle]', onFilterToggle);
        $el.on('click', '[data-fieldset-toggle]', onFieldsetToggle);
        
        $body.on('click', onBodyClick);
        $el.on('click', onElementClick);
        document.addEventListener('keyup', onKeyUp);
        
        Dispatch.on(FILTER_REMOVE_TERM, onRemoveTerm);
    };

    const destroy = () => {
        $el.off('click');
        
        $body.off('click', onBodyClick);
        $el.off('click', onElementClick);
        document.removeEventListener('keyup', onKeyUp);
        
        Dispatch.off(FILTER_REMOVE_TERM, onRemoveTerm);
    };
    
    const onBodyClick = e => {
        if (activeFilterPanel !== null) {
            closeFilterPanel(activeFilterPanel);
        }
    };

    const onElementClick = e => {
        e.stopImmediatePropagation();
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;

        if (activeFilterPanel !== null && key === 27) {
            closeFilterPanel(activeFilterPanel);
        }
    };
    
    const onFilterToggle = e => {
        const $toggle = $(e.triggerTarget);
        const isOpen = $toggle.attr('aria-expanded') === 'true';

        if (isOpen) {
            closeFilterPanel($toggle.data('filter-toggle'));
        } else {
            openFilterPanel($toggle.data('filter-toggle'));

        }
    };

    const onFieldsetToggle = e => {
        const $toggle = $(e.triggerTarget);
        const isOpen = $toggle.attr('aria-expanded') === 'true';

        if (isOpen) {
            closeFieldsetPanel($toggle.data('fieldset-toggle'));
        } else {
            openFieldsetPanel($toggle.data('fieldset-toggle'));

        }
    };

    const openFilterPanel = id => {
        if (activeFilterPanel !== null) {
            closeFilterPanel(activeFilterPanel);
        }

        const $panel = $el.find('#' + id);
        const $toggle = $el.find('[data-filter-toggle="' + id + '"]');

        $panel.css({ display: 'block' });
        $toggle.attr('aria-expanded', 'true');

        activeFilterPanel = id;
    };

    const closeFilterPanel = id => {
        const $panel = $el.find('#' + id);
        const $toggle = $el.find('[data-filter-toggle="' + id + '"]');

        $panel.css({ display: 'none' });
        $toggle.attr('aria-expanded', 'false');

        activeFilterPanel = null;
    };

    const openFieldsetPanel = id => {
        const $panel = $el.find('#' + id);
        const $toggle = $el.find('[data-fieldset-toggle="' + id + '"]');
        const $wrapper = $toggle.parent();

        $panel.css({ display: 'block' });
        $toggle.attr('aria-expanded', 'true');
        $wrapper.addClass('is-open');
    };

    const closeFieldsetPanel = id => {
        const $panel = $el.find('#' + id);
        const $toggle = $el.find('[data-fieldset-toggle="' + id + '"]');
        const $wrapper = $toggle.parent();

        $panel.css({ display: 'none' });
        $toggle.attr('aria-expanded', 'false');
        $wrapper.removeClass('is-open');
    };


    const onRemoveTerm = (key, data) => {
        if (data.term === undefined) {
            return;
        }

        if (data.term === '*') {
            resetForm();
            return;
        }

        const term = data.term.split('|');

        const name = term[0] || false;
        const value = term[1] || false;

        if (name && value) {
            const $input = $('input[name="' + name + '[]"][value="' + value + '"]');

            if ($input.length > 0) {
                $input.get(0).click();
                $el.get(0).submit();
            }
        }
    };

    const resetForm = () => {
        location.href = resetUrl;
    }

    return {
        init,
        destroy
    };
};

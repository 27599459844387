import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

import { loadFlickity } from "../lib/async-bundles";

export default el => {
    const SLIDER_SELECTOR = '[data-quotes-wrapper]';
    const SLIDE_SELECTOR = '[data-quotes-item]';

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);
    const $nav = $el.find('[data-quotes-nav]');

    let Flickity;
    let slideshow;
    let stageW;
    let currentSlide = 0;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            $nav.on('click', 'button', e => {
                onNavButtonClick($(e.triggerTarget));
            });
            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };
    
    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            height: `${$slider.height()}px`
        });

        $slides.css({ 
            display: 'block'
        });
        
        selectNum(0);
        
        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: true,
            freeScroll: false,
            contain: true,
            accessibility: true
        });

        slideshow.on('change', onChange);
    };
    
    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        slideshow.off('change');
        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');
        
        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        const vw = Viewport.width;
        if (!slideshow || vw === stageW) {
            return;
        }
        stageW = vw;
        initFlickity();
    };
    
    const onChange = index => {
        selectNum(index);
    };
    
    const selectNum = index => {
        const $oldSlide = $($slides.get(currentSlide));
        const $newSlide = $($slides.get(index));
        
        $oldSlide.removeClass('selected');
        $newSlide.addClass('selected');
        
        $nav.find('button').css({ opacity: '' });
        $($nav.find('li').get(index)).find('button').css({ opacity: 1 });
        
        const oldHeight = $slider.height();
        const newHeight = $newSlide.height();
        
        gsap.to($slider.get(0), { duration: 0.7, height: newHeight, ease: 'power3.out' });
        
        currentSlide = index;
    };
    
    const onNavButtonClick = $button => {
        slideshow.select($button.data('goto-index'));
    };
    
    return {
        init,
        destroy
    };

};

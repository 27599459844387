import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

export default el => {
    let observer;
    let isPaused = true;
    let isVisible = false;
    let tl;
    let lastW = 0;

    const scroller = el.querySelectorAll('[data-scroller]')[0];

    let { scrollTop: prevScrollTop } = Viewport;
    let scrollTimeout = null;
    let preventScrollHandler = false;
    let preventScrollHandlerTimeout = null;

    const preloadImages = () => {
        if (!window.lazySizes) {
            return;
        }
        el.querySelectorAll('img:not(.lazyloaded):not(.lazyloading)').forEach(img => {
            if (img.offsetParent === null) {
                return;
            }
            window.lazySizes.loader.unveil(img);
        });
    };

    const clearPreventScrollHandlerTimeout = () => {
        if (!preventScrollHandlerTimeout) {
            return;
        }
        clearTimeout(preventScrollHandlerTimeout);
        preventScrollHandlerTimeout = null;
    };

    const clearScrollTimeout = () => {
        if (!scrollTimeout) {
            return;
        }
        clearTimeout(scrollTimeout);
        scrollTimeout = null;
    };

    const createTimeline = () => {
        if (tl) {
            return;
        }
        
        const $firstDuplicate = $(scroller).find('[data-carousel-item="duplicate"]').eq(0);
        const loopWidth = $firstDuplicate.offset().left;
        
        tl = gsap.timeline({
            onStart() {
                preloadImages();
            },
            onComplete() {
                this.play(0);
            },
            onReverseComplete() {
                this.reverse(0);
            }
        });
        const duration = scroller.children.length * 3;
        if (!duration) {
            return;
        }
        tl.fromTo(scroller, { x: 0 }, { x: -loopWidth, duration, ease: 'none' }, 0);

        if (isPaused) {
            tl.pause();
        }
    };

    const pause = () => {
        isPaused = true;
        if (tl) {
            gsap.killTweensOf(tl);
            tl.timeScale(1);
            tl.pause();
        }
    };

    const play = () => {
        isPaused = false;
        if (tl && isVisible) {
            tl.resume();
        }
    };

    const onScroll = () => {
        clearScrollTimeout();
        const { scrollTop } = Viewport;
        const scrollDelta = Math.abs(Math.max(-100, Math.min(scrollTop - prevScrollTop, 100)));
        prevScrollTop = scrollTop;
        if (!tl || isPaused || preventScrollHandler) {
            return;
        }
        let timeScale = tl.timeScale();
        timeScale += scrollDelta/3;
        timeScale = Math.max(-100, Math.min(timeScale, 100));
        gsap.to(tl, { timeScale, duration: 0.1, ease: 'none' });
        scrollTimeout = setTimeout(() => {
            gsap.to(tl, { timeScale: 1, duration: 0.3, ease: 'Cubic.easeOut' });
            clearScrollTimeout();
        }, 10);
    };

    const onResize = () => {
        if (lastW === Viewport.width) {
            return;
        }
        preventScrollHandler = true;
        clearPreventScrollHandlerTimeout();
        preventScrollHandlerTimeout = setTimeout(() => {
            preventScrollHandler = false;
        }, 100);
        if (!tl) {
            return;
        }
        clearScrollTimeout();
        gsap.killTweensOf(tl);
        tl.kill();
        tl = null;
        gsap.killTweensOf(scroller);
        gsap.set(scroller, { clearProps: 'transform' });
        if (!isPaused) {
            createTimeline();
        }

        lastW = Viewport.width;
    };
    
    const onMouseEnterScroller = () => {
        if (!tl) {
            return;
        }
        gsap.to(tl, { timeScale: 0, duration: 0.3, ease: 'sine.out' });
    };

    const onMouseLeaveScroller = () => {
        if (!tl) {
            return;
        }
        gsap.to(tl, { timeScale: 1, duration: 0.3, ease: 'sine.out' });
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            isVisible = isIntersecting;
            if (!isVisible) {
                pause();
            } else {
                createTimeline();
                play();
            }
        });
        observer.observe(el);
        $(scroller).on('mouseenter', onMouseEnterScroller);
        $(scroller).on('mouseleave', onMouseLeaveScroller);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        $(scroller).off('mouseenter', onMouseEnterScroller);
        $(scroller).off('mouseleave', onMouseLeaveScroller);
        Viewport.off('scroll', onScroll);
        Viewport.on('resize', onResize);
        if (tl) {
            tl.kill();
            tl = null;
        }
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        clearScrollTimeout();
        clearPreventScrollHandlerTimeout();
    };

    return {
        init,
        destroy
    };
};

import gsap from 'gsap';
import serialize from 'form-serialize';
import axios from 'axios';

import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);
    const { formUrl } = props;

    const init = () => {
        $el.on('submit', 'form', e => {
            e.preventDefault();
            submit();
        });

        if ($('button[data-next-step-button]').length > 0) {
            $el.on('click', 'button[data-next-step-button]', nextStep);
            
            const $form = $el.find('form');
            const formData = serialize($form.get(0), { hash: true });
            
            $form.find('[data-step="conditionals"] input').on('change', e => {
                console.log(e.target.value);
                if (e.target.value !== '') {
                    $('button[data-next-step-button]').attr('disabled', null);
                } 
            });

            if (formData[formData.namespace] === undefined || formData[formData.namespace].values.organizationType === '' || formData[formData.namespace].values.organizationType === undefined) {
                $('button[data-next-step-button]').attr('disabled', 'disabled');
            }
        }
    };

    const destroy = () => {
        $el.off('submit');
    };
    
    const nextStep = e => {
        const $form = $el.find('form');
        const formData = serialize($form.get(0), { hash: true });
        
        const orgType = formData[formData.namespace].values.organizationType || '';
        console.log(orgType);
        
        if (orgType !== '') {
            const exitNode = $form.find('input[type="radio"][value="'+orgType+'"][data-is-exit-contion]');
            
            if (exitNode.length > 0) {
                $el.find('[data-step="conditionals"]').css({ display: 'none' });
                $el.find('[data-step="conditional-exit-receipt"]').css({ display: 'block' });
                return;
            }
            
        }
        
        $el.find('[data-step="conditionals"]').css({ display: 'none' });
        $el.find('[data-step="fields"]').css({ display: 'block' });
    };

    const submit = () => {
        const $form = $el.find('form');
        const formData = serialize($form.get(0), { hash: false });
        
        $el.find('button').attr('disabled', 'disabled').css({ opacity: 0.5 });

        axios({
            method: 'post',
            url: formUrl,
            data: formData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'text/html' },
            maxRedirects: 0,
            validateStatus: status => status >= 200 && status < 400
        })
            .then(res => {
                const { status, data } = res || {};

                if (status === 302 && (typeof res.headers['x-redirect'] !== 'undefined')) {
                    loadData(res.headers['x-redirect']);
                } else if (status === 200 && data) {
                    // Do something
                    updateForm(data);
                } else {
                    // Do something else
                }
            })
            .catch((error, res) => {
                console.log(error, res);
            });
    };

    const loadData = url => {
        if (formUrl.indexOf('ddev.site:3000/')) {
            //url = url.replace('ddev.site/', 'ddev.site:3000/');
        }
        
        axios({
            url,
            method: 'get',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'text/html' },
        })
            .then(res => {
                const { status, data } = res || {};

                if (status === 200 && data) {
                    // Do something
                    updateForm(data);
                } else {
                    // Do something else
                }
            })
            .catch((error, res) => {
                // console.log(error, res);
            });
    };

    const updateForm = html => {
        const $html = $(`<div>${html}</div>`);
        const $innerContent = $html.find('[data-inner-content]');
        const $innerHeading = $html.find('[data-inner-heading]');

        $el.find('[data-inner-content]').empty().append($innerContent.get(0).children);
        $el.find('[data-inner-heading]').empty().append($innerHeading.get(0).children);

    };

    return {
        init,
        destroy
    };
};

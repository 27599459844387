import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    const $image = $el.find('[data-image]');
    const $video = $el.find('[data-video]');
    const video = $video.get(0);
    const videoSource = $video.attr('data-video');

    let isLoaded = false;
    let cantPlay = false;
    let videoIsPlaying = false;
    let videoFallbackTimer = null;
    let videoAspectRatio = $image.width() / $image.height();
    let observer = null;

    const init = () => {
        if (!video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlayVideo();
            return;
        }

        if (!videoSource) {
            return;
        }

        $el.on('mouseenter', onMouseEnter);
        $el.on('mouseleave', onMouseLeave);

        killVideo();
        video.src = videoSource;
        video.muted = true;
    };

    const onMouseEnter = () => {
        if (!cantPlay && $('html').hasClass('using-mouse')) {
            if (!isLoaded) {
                playAndCatch();
            } else {
                showVideo();
            }
        }
    };

    const onMouseLeave = () => {
        if (!cantPlay) {
            hideVideo();
        }        
    };
    
    const showVideo = () => {
        if (video) {
            video.play();
        }
        gsap.to($image.nodes, { duration: 0.3, delay: 0.2, opacity: 0 });
    };

    const hideVideo = () => {
        gsap.to($image.nodes, { duration: 0.3, delay: 0.2, opacity: 1, onComplete: () => {
            if (video) {
                video.pause();
            }
        } });
    };

    const destroy = () => {
        killVideo();
    };

    const playAndCatch = () => {
        addVideoEventListeners();
        startFallbackTimer(1000);

        const promise = video.play();

        if (promise !== undefined) {
            promise.then(() => {
                stopFallbackTimer();
            }).catch(e => {
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    cantPlayVideo();
                }
            });
        }
    };

    const cantPlayVideo = () => {
        cantPlay = true;
        killVideo();

        if ($video.length) {
            $video.remove();
        }
    };
    
    const stopFallbackTimer = () => {
        clearTimeout(videoFallbackTimer);
        videoFallbackTimer = null;
    };

    const startFallbackTimer = interval => {
        stopFallbackTimer();
        videoFallbackTimer = setTimeout(onFallbackTimer, interval);
    };

    const addVideoEventListeners = () => {
        video.addEventListener('timeupdate', onTimeUpdate);
        video.addEventListener('loadstart', onLoadStart);
        video.addEventListener('loadedmetadata', onLoadStart);
        video.addEventListener('loadeddata', onLoadStart);
        video.addEventListener('canplay', onLoadStart);
    };

    const removeVideoEventListeners = () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
        video.removeEventListener('loadstart', onLoadStart);
        video.removeEventListener('loadedmetadata', onLoadStart);
        video.removeEventListener('loadeddata', onLoadStart);
        video.removeEventListener('canplay', onLoadStart);
    };

    const killVideo = () => {
        stopFallbackTimer();
        removeVideoEventListeners();
        video.pause();
        videoIsPlaying = false;
    };

    const onFallbackTimer = () => {
        cantPlayVideo();
    };

    const onTimeUpdate = () => {
        if (!video || video.currentTime < 0.001) {
            return;
        }
        removeVideoEventListeners();
        stopFallbackTimer();

        $video.css({ opacity: 100 });
        isLoaded = true;
        showVideo();

        videoIsPlaying = true;
    };

    const onLoadStart = () => {
        if (videoIsPlaying) {
            return;
        }
        startFallbackTimer(2000);
    };
    

    return {
        init,
        destroy
    };
};

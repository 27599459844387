import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $slides = $el.find('[data-slideshow-item]');
    const $nav = $el.find('[data-slideshow-nav]');

    let isAnimating = false;
    let currentSlide = 0;
    let direction = 'right';

    const init = () => {
        $nav.on('click', 'button', e => {
            onNavButtonClick($(e.triggerTarget));
        });
    };

    const destroy = () => {

    };
    
    const gotoSlide = index => {
        const $oldSlide = $($slides.get(currentSlide));
        const $newSlide = $($slides.get(index));
        
        $nav.find('li button').css({ opacity: '' });
        $($nav.find('li').get(index)).find('button').css({ opacity: 1 });
        
        isAnimating = true;
        const moveAmount = Viewport.width/15;
        
        gsap.set($newSlide.get(0), { opacity: 1, zIndex: 5, display: 'block' });
        gsap.set($newSlide.find('[data-content-element]').nodes, { opacity: 0, x: direction === 'right' ? moveAmount : -moveAmount });
        gsap.set($newSlide.find('[data-image-wrapper]').nodes, { opacity: 0 });
        
        const timeline = gsap.timeline({ onComplete: () => {
            gsap.set($oldSlide.get(0), { display: 'none' });
            gsap.set($newSlide.get(0), { zIndex: '' });
            isAnimating = false;
        }});
        
        timeline.to($oldSlide.find('[data-content-element]').nodes.reverse(), { duration: 0.4, ease: 'quart.in', x: direction === 'right' ? -moveAmount : moveAmount, stagger: 0.07 }, 0);
        timeline.to($oldSlide.find('[data-content-element]').nodes.reverse(), { duration: 0.4, ease: 'linear', opacity: 0, stagger: 0.07 }, 0);
        timeline.to($newSlide.find('[data-image-wrapper]').nodes, { duration: 0.6, opacity: 1 }, 0.65);
        timeline.to($newSlide.find('[data-content-element]').nodes, { duration: 0.8, ease: 'quart.out', x: 0, stagger: 0.07 }, 1);
        timeline.to($newSlide.find('[data-content-element]').nodes, { duration: 0.5, ease: 'linear', opacity: 1, stagger: 0.07 }, 1);
        
        currentSlide = index;
    };
    
    const onNavButtonClick = $button => {
        if (isAnimating) {
            return;
        }
        
        const navTypeValue = $button.data('nav-button');
        let nextSlide = 0;
        
        if (navTypeValue === 'next') {
            direction = 'right';
            nextSlide = currentSlide + 1;
        } else if (navTypeValue === 'prev') {
            direction = 'left';
            nextSlide = currentSlide - 1;
        } else if (Number.isInteger(navTypeValue)){
            nextSlide = navTypeValue;
            direction = nextSlide < currentSlide ? 'left' : 'right';
        }
        
        if (nextSlide < 0) {
            nextSlide = $slides.length - 1;
        } else if (nextSlide >= $slides.length) {
            nextSlide = 0;
        }
        
        gotoSlide(nextSlide);
    };
    
    return {
        init,
        destroy
    };

};

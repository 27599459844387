import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';

import * as events from '../lib/events';

const VIDEO_TIMEOUT = 1000;

export default (el, { sources, index }) => {
    const $el = $(el);
    const video = $el.find('[data-video]').get(0);
    const image = $el.find('img[hidden],picture[hidden]').get(0);

    let isPlaying = false;
    let isActivated = false;

    let timer;
    let listenersInited = false;

    const show = () => {
        gsap.to(video, {
            opacity: 1,
            duration: 0.7
        });
    };

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        clearTimer();
        image.hidden = false;
        show();
    };

    const onTimeout = () => {
        image.hidden = false;
        show();
    };

    const onPlaying = () => {
        if (isPlaying) {
            return;
        }

        if (isActivated) {
            isPlaying = true;
            clearTimer();
            show();
        }
    };

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(onTimeout, VIDEO_TIMEOUT);
    };

    const onTimeUpdate = e => {
        const { currentTime } = e.target;

        if (currentTime && currentTime > 0.001) {
            onPlaying();
        }
    };

    const onLoadStart = e => {
        if (!timer) {
            return;
        }
        setTimer();
    };
    
    const initListeners = () => {
        if (!listenersInited) {
            video.addEventListener('loadstart', onLoadStart);
            video.addEventListener('loadedmetadata', onLoadStart);
            video.addEventListener('loadeddata', onLoadStart);
            video.addEventListener('canplay', onLoadStart);
            video.addEventListener('timeupdate', onTimeUpdate);
            
            listenersInited = true;
        }
    };

    const playAndCatch = () => {
        createTimeUpdateTick();
        initListeners();

        if (isActivated) {
            try {
                const promise = video.play();
                if (promise !== undefined) {
                    promise
                        .then(clearTimer)
                        .catch(e => {
                            if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                cantPlay();
                            }
                        });
                }
            } catch (error) {
                console.error(error);
                cantPlay();
            }
        }
    };
    
    const pause = () => {
        clearTimer();
        clearTimeUpdateTick();
        video.pause();
        video.currentTime = 0;
        isPlaying = false;
    };

    const swapSource = ({ src }) => {
        if ($(video)
            .attr('src') === src) {
            return;
        }
        $(video)
            .attr('src', src);
        
        playAndCatch();
    };

    const bootVideo = () => {
        if (sources.length > 1) {
            sources.forEach(source => {
                const mq = window.matchMedia(source.media);
                const onChange = e => {
                    if (e.matches) {
                        swapSource(source);
                    }
                };
                try {
                    mq.addEventListener('change', onChange);
                } catch (error) {
                    mq.addListener(onChange);
                }
                // Sets initial breakpoint
                if (mq.matches) {
                    swapSource(source);
                }
            });
        } else {
            swapSource(sources[0]);
        }
    };
    
    const onActivateVideo = (key, data) => {
        if (data.index === index) {
            isActivated = true;
            show();
            
            setTimeout(() => {
                playAndCatch();
            }, 2000);
        }
    };

    const onDeactivateVideo = (key, data) => {
        if (data.index === index) {
            isActivated = false;
            pause();
        }
    };

    const createTimeUpdateTick = () => {
        gsap.ticker.add(onVideoTick);
    };
    
    const clearTimeUpdateTick = () => {
        gsap.ticker.remove(onVideoTick);
    };
    
    const onVideoTick = () => {
        Dispatch.emit(events.FRONTPAGE_VIDEO_TICK, { index, currentTime: video.currentTime, duration: video.duration })
    };
    
    const onVideoStartDrag = (key, data) => {
        if (isPlaying && (data.index === index)) {
            video.pause();
        }
    };
    
    const onVideoEndDrag = (key, data) => {
        if (isPlaying && (data.index === index)) {
            //video.pause();
        }
    };
    
    const onVideoSkipTo = (key, data) => {
        if (data.index === index) {
            video.currentTime = data.newTime;
        }
    };
    
    const init = () => {
        if (!video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlay();
            return;
        }
        
        bootVideo();
        
        Dispatch.on(events.ACTIVATE_FRONTPAGE_VIDEO, onActivateVideo);
        Dispatch.on(events.DEACTIVATE_FRONTPAGE_VIDEO, onDeactivateVideo);
        Dispatch.on(events.FRONTPAGE_VIDEO_START_DRAG, onVideoStartDrag);
        Dispatch.on(events.FRONTPAGE_VIDEO_END_DRAG, onVideoEndDrag);
        Dispatch.on(events.FRONTPAGE_VIDEO_SKIPTO, onVideoSkipTo);
    };

    const destroy = () => {
        clearTimer();

        video.removeEventListener('loadstart', onLoadStart);
        video.removeEventListener('loadedmetadata', onLoadStart);
        video.removeEventListener('loadeddata', onLoadStart);
        video.removeEventListener('canplay', onLoadStart);
        video.removeEventListener('timeupdate', onTimeUpdate);
    };

    return {
        init,
        destroy
    };

};

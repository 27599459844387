import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import * as Events from './events';
import Viewport from '../core/Viewport';


const transitionOut = (key, url) => {
    const items = $('[data-root-animation-element]').nodes;

    gsap.to(items, {
        duration: 0.3, opacity: 0, y: Viewport.breakpoint.size > 750 ? -50 : -20, ease: 'sine.in', onComplete: () => {
            document.location = url;
        }
    });
};

const init = () => {
    Dispatch.on(Events.TRANSITION_OUT, transitionOut);

    window.onpageshow = e => {
        if (e.persisted) {
            $('[data-root-animation-element]').attr('style', null);
        }
    };
};

const destroy = () => {
    Dispatch.off(Events.TRANSITION_OUT, transitionOut);
};

export default {
    init,
    destroy
};

import gsap from 'gsap';
import axios from 'axios';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';
import * as Events from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $panel = $el.find('[data-modal-panel]');
    const $announcer = $el.find('[data-announcer]');
    const $closeBtn = $el.find('[data-close-button]');
    const $content = $el.find('[data-modal-content]');

    let isOpen = false;

    const init = () => {
        Dispatch.on(Events.OPEN_MODAL, openModal);
        $('body').on('keyup', onBodyKeyUp);
        $closeBtn.on('click', closeModal);
        $el.on('click', e => {
            if (e.target === el) {
                closeModal();
            }
        })
    };

    const destroy = () => {
        Dispatch.off(Events.OPEN_MODAL, openModal);
        $('body').off('keyup');
        $closeBtn.off('click');
        $el.off('click');
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeModal();
        }
    };

    const openModal = (key, href) => {
        $el.attr('hidden', null).css({ opacity: 0 });
        gsap.set($panel.get(0), { x: '100%' });
        $content.css({ opacity: 0 });

        gsap.to($el.get(0), { opacity: 1, duration: 0.3 });
        gsap.to($panel.get(0), { x: '0%', delay: 0.2, duration: 0.8, ease: 'quint.out' });

        isOpen = true;

        axios({
            url: href,
            method: 'get',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'text/html' },
        })
            .then(res => {
                const { status, data } = res || {};

                if (status === 200 && data) {
                    addContent(data);
                } else {
                    // Do something else
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const closeModal = () => {
        //$el.attr('hidden', '');
        
        isOpen = false;
        gsap.to($panel.get(0), { x: '100%', duration: 0.25, ease: 'sine.in' });
        gsap.to($el.get(0), {
            opacity: 0, duration: 0.3, delay: 0.15, onComplete: () => {
                $el.attr('hidden', '');
                $content.empty();
            }
        });

    };

    const addContent = text => {
        $content.empty();
        $content.append($(text));
        Components.init($content);
        gsap.to($content.get(0), { opacity: 1, duration: 0.6, delay: 0.4 });
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { FILTER_REMOVE_TERM } from '../lib/events';


export default (el, props) => {
    const $el = $(el);

    const init = () => {
        $el.on('click', '[data-filter-clear]', onClearButtonClicked);
    };

    const destroy = () => {
        $el.off('click');
    };

    const onClearButtonClicked = e => {
        e.preventDefault();
        Dispatch.emit(FILTER_REMOVE_TERM, { term: $(e.triggerTarget).data('filter-clear') });
    };
    
    return {
        init,
        destroy
    };
};

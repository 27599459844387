import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default el => {
    const BP_IS_ACTIVATED = 980;
    
    const $el = $(el);
    
    const $slides = $el.find('[data-slide-panel]');
    const $nav = $el.find('[data-slides-nav]');
    const $navProgress = $nav.find('[data-slides-nav-progress]');
    const $scrollPanels = $el.find('[data-scroll-panel]');

    let tl = null;
    let panelObserver = null;
    let moduleObserver;
    let activePanel;
    let hasPreloaded = false;
    let isInited = false;
    let lastTime = 1;
    let resizeTimeout = null;
    let activeIndex = 0;
    
    const init = () => {
        panelObserver = new IntersectionObserver(onPanelObserve, {
            threshold: [0, 0.25, 0.5, 0.75, 1]
        });
        
        $el.find('[data-scroll-panel]').each(panel => {
            panelObserver.observe(panel);
        });

        $nav.on('click', 'button', e => {
            onScrollToSlide($(e.triggerTarget));
        });
        
        moduleObserver = new IntersectionObserver(onModuleObserve, {
            rootMargin: "0px 0px 25% 0px"
        });
        moduleObserver.observe(el);
        
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        
        onResize();
        
        $slides.css({ opacity: 1 });
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        $nav.off('click');
    };
    
    const onResize = () => {
        clearTimeout(resizeTimeout);
        
        resizeTimeout = setTimeout(() => {
            reset();
            
            if (Viewport.breakpoint.size >= BP_IS_ACTIVATED) {
                initSlides();
            }
        }, 200);
    };
    
    const onScroll = () => {
        updateNavProgress(true);
    };
    
    const reset = () => {
        if (tl) {
            lastTime = tl.time();
            tl.kill();
        }
        
        $el.find('[data-slide-image]').attr('style', null);
        $el.find('[data-content-element]').attr('style', null);
        $el.find('[data-slide-image-overlay]').attr('style', null);
        
        isInited = false;
    };
    
    const initSlides = () => {
        reset();
        
        tl = new gsap.timeline({ paused: true });
        
        $slides.each((slide, num) => {
            const $slide = $(slide);
            const $image = $slide.find('[data-slide-image]');
            const $imageOverlay = $slide.find('[data-slide-image-overlay]');
            const $text = $slide.find('[data-content-element]');
            
            gsap.set($text.nodes, { opacity: 1, x: 0 });
            gsap.set($image.get(0), { opacity: 1, x: 0, scale: 1 });
            gsap.set($imageOverlay.get(0), { opacity: 0 });
            
            tl.from($text.nodes, { duration: 1, opacity: 0, x: 80, stagger: 0.1, ease: 'quart.out' }, num*2);
            tl.to($text.nodes, { duration: 1, opacity: 0, x: -80, stagger: 0.1, ease: 'quart.in' }, (num*2) + 0.8);
            
            tl.from($image.get(0), { duration: 0.8, x: Viewport.width/2, ease: 'sine.out' }, (num*2) + 0.2);
            tl.from($image.get(0), { duration: 0.3, opacity: 0, ease: 'sine.out' }, (num*2) + 0.2);
            
            for (let i=1; i<$slides.length-num; i++) {
                tl.to($imageOverlay.get(0), { duration: 0.2, ease: 'linear', opacity: 1 }, (num*2) + (i*2));
                tl.to($image.get(0), { duration: 0.4, ease: 'sine.inOut', scale: 1-(i*0.06) }, (num*2) + (i*2));
                tl.to($image.get(0), { duration: 0.4, ease: 'sine.inOut', x: -(Viewport.breakpoint.size < 1420 ? 40 : 50)*i }, (num*2) + (i*2) + 0.3);
            }
        });

        tl.pause(lastTime);
        isInited = true;
    };
    
    const onScrollToSlide = $button => {
        const $panel = $scrollPanels.eq($button.data('slide-goto-index'));
        gsap.to(window, { duration: 0.3, ease: 'sine.out', scrollTo: { y: $panel.offset().top - (Viewport.height/2) + 2 } });
    };

    const onPanelObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0.5 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }
        intersecting = intersecting.sort((a, b) => parseFloat(b.intersectionRatio) - parseFloat(a.intersectionRatio)).map(({ target }) => target);

        setActivePanel(intersecting.shift());
    };
    
    const setActivePanel = panel => {
        activePanel = panel;
        const index = parseInt(panel.dataset.scrollPanel);
        if (tl) {
            gsap.to(tl, { duration: 1.5, ease: 'sine.out', time: (index*2) + 1 });
        }

        $nav.find('li').removeClass('selected');
        $($nav.find('li').get(index)).addClass('selected');
        
        activeIndex = index; 
        updateNavProgress(false);
    };
    
    const onModuleObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }

        if (!hasPreloaded) {
            $el.find('img.lazyload').addClass('lazypreload');

            $el.find('[data-component="VideoLoop"]').each(video => {
                $(video).data('boot-video')()
            });
            
            hasPreloaded = true;
            moduleObserver.disconnect();
        }
    };
    
    const updateNavProgress = onlyProgress => {
        const $buttons = $nav.find('button');

        if (!onlyProgress) {
            $buttons.removeClass('selected');
            $buttons.eq(activeIndex).addClass('selected');
        }

        const startY = $buttons.eq(activeIndex).position().top;
        let targetHeight = 10;
        
        if (activeIndex < $buttons.length-1) {
            const $activePanel = $scrollPanels.eq(activeIndex);
            const $nextPanel = $scrollPanels.eq(activeIndex+1);

            const factor = Math.min(1, (Math.max(0, (((Viewport.scrollTop + Viewport.height/2)-$activePanel.offset().top)) / ($nextPanel.offset().top - $activePanel.offset().top))));
            
            if (factor > 0.4) {
                targetHeight = 10 + (30*((factor-0.4)/0.6));
            }
        } 
        
        gsap.to($navProgress.get(0), { duration: 0.4, ease: 'power2.out', y: startY, height: targetHeight });
    };
    
    return {
        init,
        destroy
    };

};

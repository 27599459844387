import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import Config from './core/Config';

import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import PageTransition from './lib/PageTransition';

import * as Events from './lib/events';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollTrigger);

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    PageTransition.init();
    lazySizes.init();
    
    $('body').on('click', 'a', handleClicks);
};

const isKeyCombo = e => e && (e.which === 2 || e.button === 4 || e.metaKey || e.shiftKey);

const handleClicks = e => {
    const target = e.triggerTarget;
    const href = target.getAttribute('href');

    if (!isKeyCombo(e) && href && href.indexOf('#') === -1 && href.indexOf('mailto:') === -1 && href.indexOf('tel:') === -1 && href.indexOf(document.location.hostname) > -1 && target.getAttribute('target') !== '_blank' && !target.hasAttribute('download') && !target.hasAttribute('data-transition-disabled')) {
        e.preventDefault();
        
        if (isModalUrl(href)) {
            Dispatch.emit(Events.OPEN_MODAL, href);
        } else {
            Dispatch.emit(Events.TRANSITION_OUT, href);
        }
    }
};

const isModalUrl = href => {
    const modalUrls = Config.get('modalUrls') || [];
    let found = false;
    
    modalUrls.forEach(url => {
        const searchPattern = new RegExp(`^${url}`);
        if (searchPattern.test(href)) {
            found = true;
        }
    });

    return found;
};

require('doc-ready')(init);

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const $el = $(el);

    let $items = $el.find('[data-topic-item]');
    let $dots = $el.find('[data-topic-dots]');

    const init = () => {
        onResize();
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    const onResize = () => {
        $dots.css({ display: 'block' });
        const availWidth = $el.width() - $dots.width() - 10;

        let usedWidth = 0;
        let isFull = false;

        $items.each((item, i) => {
            const $item = $(item);
            $item.css({ display: 'block' });
            
            if (!isFull) {
                const style = window.getComputedStyle(item);
                const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
                const width = item.offsetWidth + margin; 
                
                if (usedWidth + width > availWidth) {
                    isFull = true;
                    $(item).css({ display: 'none' });
                } else {
                    usedWidth += width;
                    $(item).css({ display: 'block' });
                }
            } else {
                    $(item).css({ display: 'none' });
            }
        });
        
        $dots.css({ display: isFull ? 'block' : 'none' });
    };

    return {
        init,
        destroy
    };
};

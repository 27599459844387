import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';


export default el => {
    const $el = $(el);

    const $itemsWrapper = $el.find('[data-cards-wrapper]');
    const $items = $el.find('[data-card-item]');

    const $prevBtn = $el.find('[data-prev-button]');
    const $nextBtn = $el.find('[data-next-button]');

    let currentItem = 0;
    let boxWidth = 0;
    let boxOffsetX = 0;

    const init = () => {
        Viewport.on('resize', onResize);
        $prevBtn.on('click', onPrevClick);
        $nextBtn.on('click', onNextClick);

        //debug
        start();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    const onResize = () => {
        resetItems();
        positionItems(true);
    };

    const onPrevClick = e => {
        e.preventDefault();

        if (currentItem > 0) {
            currentItem -= 1;
            positionItems(false);
        }
    };

    const onNextClick = e => {
        e.preventDefault();

        if (currentItem < $items.length - 1) {
            currentItem += 1;
            positionItems(false);
        } 
    };

    const start = () => {
        $itemsWrapper.css({ display: 'block' });
        resetItems();
        positionItems(true);

        gsap.to($itemsWrapper.get(0), { duration: 1, ease: 'quart.out', opacity: 1 })
    };
    
    const resetItems = () => {
        const $boxes = $itemsWrapper.find('[data-card-item-box]');
        gsap.set($boxes.nodes, { x: 0, rotate: 0, height: '' });
        $boxes.removeClass('active');

        const $firstBox = $($boxes.get(0));
        boxWidth = $firstBox.width();
        boxOffsetX = $firstBox.offset().left;
        
        let tallestBox = 0;
        
        $boxes.each(box => { 
            const $box = $(box);
            
            if ($box.height() > tallestBox) {
                tallestBox = $box.height();
            }
        })
        
        $boxes.height(tallestBox);
    };

    const positionItems = immediate => {
        checkPrevNext();
        $items.each((item, i) => {
            positionItem(item, i, immediate)
        });
    };

    const positionItem = (item, itemIndex, immediate) => {
        const $box = $(item).find('[data-card-item-box]');

        if (itemIndex === currentItem) {
            gsap.to($box.get(0), { duration: immediate ? 0 : 0.8, ease: 'quint.out', x: 0, rotate: 0 });
        } else if (itemIndex > currentItem) {
            gsap.to($box.get(0), { duration: immediate ? 0 : 0.8, ease: 'quint.out', x: Viewport.width - boxOffsetX - getSideOffset(), rotate: gsap.utils.random(0, 5) * (itemIndex % 2 ? 1 : -1) });
        } else if (itemIndex < currentItem) {
            gsap.to($box.get(0), { duration: immediate ? 0 : 0.8, ease: 'quint.out', x: -boxOffsetX - boxWidth + getSideOffset('left'), rotate: gsap.utils.random(0, 5) * (itemIndex % 2 ? 1 : -1) });
        }

        if (itemIndex === currentItem) {
            $box.addClass('active');
        } else {
            $box.removeClass('active');
        }
        
        const distance = Math.abs(currentItem - itemIndex);
        $box.css({ filter: 'brightness(' + (1 - (Math.max(0, distance-1)*0.1)) + ')', zIndex: 10-distance });
    };

    const checkPrevNext = () => {
        $prevBtn.attr({ disabled: currentItem <= 0 ? '' : null });
        $nextBtn.attr({ disabled: currentItem >= $items.length - 1 ? '' : null });
    };

    const getSideOffset = (direction) => {
        const bpSize = Viewport.breakpoint.size

        if (direction === 'left' && bpSize < 750) {
            return -30;
        }

        if (bpSize < 750) {
            return 30;
        } else if (bpSize < 980) {
            return 40;
        } else if (bpSize < 1420) {
            return 60;
        }

        return 90;
    };

    return {
        init,
        destroy
    };

};

import gsap from 'gsap';
import $ from '../core/Dom';

const VIDEO_TIMEOUT = 6000;

export default (el, { sources, useObserver = true }) => {
    const $el = $(el);
    const video = $el.find('[data-video]').get(0);
    const image = $el.find('img[hidden],picture[hidden]').get(0);

    let isPlaying = false;
    let isBooted = false;
    
    let observer = null;
    let timer;

    const show = () => {
        gsap.to(video, {
            opacity: 1,
            duration: 0.7
        });
    };

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        clearTimer();
        image.hidden = false;
        show();
        
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    };

    const onTimeout = () => {
        image.hidden = false;
        show();

        console.log('timeout');
    };

    const onPlaying = () => {
        if (isPlaying) {
            return;
        }

        isPlaying = true;
        clearTimer();
        show();

        console.log('playing');
    };

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(onTimeout, VIDEO_TIMEOUT);
    };

    const onTimeUpdate = e => {
        const { currentTime } = e.target;

        if (currentTime && currentTime > 0.001) {
            onPlaying();
        }
    };

    const onLoadStart = e => {
        if (!timer) {
            return;
        }
        setTimer();
    };

    const playAndCatch = () => {
        video.addEventListener('loadstart', onLoadStart);
        video.addEventListener('loadedmetadata', onLoadStart);
        video.addEventListener('loadeddata', onLoadStart);
        video.addEventListener('canplay', onLoadStart);
        video.addEventListener('timeupdate', onTimeUpdate);

        try {
            const promise = video.play();
            if (promise !== undefined) {
                promise
                    .then(clearTimer)
                    .catch(e => {
                        if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                            cantPlay();
                        }
                    });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };

    const swapSource = ({ src }) => {
        if ($(video).attr('src') === src) {
            return;
        }
        $(video).attr('src', src);
        playAndCatch();
    };

    const bootVideo = () => {
        if (isBooted) {
            return;
        }
        
        if (sources.length > 1) {
            sources.forEach(source => {
                const mq = window.matchMedia(source.media);
                const onChange = e => {
                    if (e.matches) {
                        swapSource(source);
                    }
                };
                try {
                    mq.addEventListener('change', onChange);
                } catch (error) {
                    mq.addListener(onChange);
                }
                // Sets initial breakpoint
                if (mq.matches) {
                    swapSource(source);
                }
            });
        } else {
            swapSource(sources[0]);
        }

        setTimer();
        isBooted = true;
    };
    
    const restart = () => {
        if (video) {
            video.play();
        }
    };

    const pause = () => {
        if (video) {
            video.pause();
        }
    };

    const init = () => {
        if (!video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlay();
            return;
        }
        
        $el.data('boot-video', () => { bootVideo(); });

        if (useObserver) {
            observer = new IntersectionObserver(([{ isIntersecting }]) => {
                if (isIntersecting) {
                    if (!isBooted) {
                        bootVideo();
                    } else {
                        restart();
                    }
                } else {
                    if (isBooted && isPlaying) {
                        pause();
                    }
                }
                
            });
            observer.observe(el);
        } else {
            bootVideo();
        }
    };

    const destroy = () => {
        clearTimer();

        if (observer) {
            observer.disconnect();
            observer = null;
        }

        video.removeEventListener('loadstart', onLoadStart);
        video.removeEventListener('loadedmetadata', onLoadStart);
        video.removeEventListener('loadeddata', onLoadStart);
        video.removeEventListener('canplay', onLoadStart);
        video.removeEventListener('timeupdate', onTimeUpdate);
    };

    return {
        init,
        destroy,
        bootVideo
    };

};

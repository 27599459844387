import $ from '../core/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const { countryCode } = props;
    const $countriesPanel = $el.find('#countries-panel');
    
    const currentCountry = '';

    const init = () => {
        $el.on('click', '[data-countries-toggle]', onCountriesToggle);
        $el.on('click', '[data-country-button]', onCountrySelect);

        const $usersCountry = $el.find('[data-country-button-code="' + countryCode.toLowerCase() + '"]');

        if ($usersCountry.length > 0) {
            $usersCountry.get(0).click();
        } else {
            const $fallbackCountry = $el.find('[data-country-button-code="*"]');
            
            if ($fallbackCountry.length > 0) {
                $fallbackCountry.get(0).click();
            } else {
                $el.find('[data-country-button]').get(0).click();
            }
        }
        
    };

    const destroy = () => {
        $el.off('click', '[data-countries-toggle]');
        $el.off('click', '[data-country-button]');
    };

    const onCountriesToggle = e => {
        const $toggle = $(e.triggerTarget);
        const isOpen = $toggle.attr('aria-expanded') === 'true';

        if (isOpen) {
            closeCountriesPanel($toggle);
        } else {
            openCountriesPanel($toggle);
        }
    };

    const onCountrySelect = e => {
        const $btn = $(e.triggerTarget);
        showCountry($btn);
        closeCountriesPanel($el.find('[data-countries-toggle]'));
    };

    const closeCountriesPanel = $toggle => {
        $countriesPanel.css({ display: 'none' });
        $toggle.attr('aria-expanded', 'false');
    };

    const openCountriesPanel = $toggle => {
        $countriesPanel.css({ display: 'block' });
        $toggle.attr('aria-expanded', 'true');
    };

    const showCountry = $btn => {
        $el.find('[data-country-toggle-text]').text($btn.find('span').text());
        
        if ($btn.data('country-button') !== currentCountry) {
            $el.find('[data-country-button] svg').css({ opacity: 0 });
            $btn.find('svg').css({ opacity: 1 });
            $el.find('[data-manager]').css({ display: '' });
            $el.find('[data-manager="' + $btn.data('country-button') + '"]').css({ display: 'grid' });
        }
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $button = $el.find('button');
    const $panel = $el.find('[data-mobile-submenu-panel]');

    const init = () => {
        $button.on('click', onPanelToggle);
    };

    const destroy = () => {
        $button.off('click');
    };

    const onPanelToggle = e => {
        e.preventDefault();

        if ($button.attr('aria-expanded') === 'false') {
            openPanel();
        } else {
            closePanel();
        }
    };

    const openPanel = () => {
        $el.addClass('is-open');
        $panel.css({ display: 'block', opacity: 0, height: '' });
        $button.attr('aria-expanded', 'true');

        const targetHeight = $panel.height();
        gsap.set($panel.get(0), { height: 0 });
        gsap.to($panel.get(0), { duration: 0.4, ease: 'quint.out', height: targetHeight });
        gsap.to($panel.get(0), { delay: 0.2, duration: 0.5, opacity: 1 });
    };

    const closePanel = () => {
        $el.removeClass('is-open');
        $button.attr('aria-expanded', 'false');
        gsap.to($panel.get(0), { duration: 0.1, opacity: 0 });
        gsap.to($panel.get(0), {
            duration: 0.2, ease: 'sine.out', height: 0, onComplete: () => {
                $panel.css({ display: 'none' });
            }
        });
    };

    return {
        init,
        destroy
    };
};

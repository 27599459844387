import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default el => {
    const BP_IS_ACTIVATED = 980;
    
    const $el = $(el);
    const $slidesWrapper = $el.find('[data-slides-wrapper]');
    const $slides = $el.find('[data-slide-panel]');
    const $nav = $el.find('[data-slides-nav]');
    const $navProgress = $nav.find('[data-slides-nav-progress]');

    let tl = null;
    let moduleObserver;
    let activePanel;
    let hasPreloaded = false;
    let isInited = false;
    let lastTime = 1;
    let resizeTimeout = null;
    let slideHeight = 0;
    
    const init = () => {
        $nav.on('click', 'button', e => {
            onNavButtonClick($(e.triggerTarget));
        })
        
        moduleObserver = new IntersectionObserver(onModuleObserve, {});
        moduleObserver.observe(el);
        
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $nav.off('click');
        clearTimeout(resizeTimeout);
    };
    
    const onResize = () => {
        clearTimeout(resizeTimeout);
        slideHeight = $slides.eq(0).height();
        
        resizeTimeout = setTimeout(() => {
            reset();
            
            if (Viewport.breakpoint.size >= BP_IS_ACTIVATED) {
                initSlides();
                updateNavProgress();
            }
        }, 200);
    };
    
    const onScroll = () => {
        if (tl) {
            tl.pause(Viewport.scrollTop - $el.offset().top);
            updateNavProgress();
        }
    };
    
    const reset = () => {
        if (tl) {
            lastTime = tl.time();
            tl.revert();
        }
        
        /* todo
        $el.find('[data-slide-image]').attr('style', null);
        $el.find('[data-content-element]').attr('style', null);
        $el.find('[data-slide-image-overlay]').attr('style', null);
        */
        
        isInited = false;
    };
    
    const initSlides = () => {
        reset();
        tl = new gsap.timeline();
        let prevSlides = [];
        let currentStart = 0;
        
        $slides.each((slide, num) => {
            const $slide = $(slide);
            
            if (prevSlides.length > 0) {
                for (let i=prevSlides.length-1; i>=0; i--) {
                    const $prevSlide = prevSlides[i];
                    const $contents = $prevSlide.find('[data-slide-text], [data-slide-image]');
                    
                    tl.to($prevSlide.get(0), { duration: $slide.height(), y: -20*(prevSlides.length-i), scale: 1 - ((prevSlides.length-i)*0.05), ease: 'sine.inOut' }, currentStart - (Viewport.height * 0.8));
                    tl.to($contents.nodes, { duration: $slide.height()*0.75, opacity: 0.3, ease: 'sine.inOut' }, currentStart - (Viewport.height * 0.8));
                }
            }
            
            currentStart += $slide.height();
            prevSlides.push($slide);
        });

        tl.pause(lastTime);
        onScroll();
        isInited = true;
    };
    
    const onNavButtonClick = $button => {
        const index = $button.data('slide-goto-index');
        
        let targetY = $slidesWrapper.offset().top;
        
        for (let i=0; i<index; i++) {
            const $slide = $slides.eq(i);
            targetY += $slide.height() + 2;
        }
        
        gsap.to(window, { duration: 0.8, ease: 'quint.inOut', scrollTo: { y: targetY } });
    };
    
    const onModuleObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }

        if (!hasPreloaded) {
            $el.find('img.lazyload').addClass('lazypreload');
            hasPreloaded = true;
            moduleObserver.disconnect();
        }
    };
    
    const updateNavProgress = onlyProgress => {
        const $buttons = $nav.find('button');
        
        const positionInModule = Math.max(0, Viewport.scrollTop - $slidesWrapper.offset().top);
        const activeSlide = Math.min($buttons.length-1, Math.floor(positionInModule / slideHeight));

        $buttons.removeClass('selected');
        $buttons.eq(activeSlide).addClass('selected');

        const startY = $buttons.eq(activeSlide).position().top;
        let targetHeight = 10;
        
        if (activeSlide < $buttons.length-1) {
            const factor = Math.min(1, (Math.max(0, ((positionInModule-((activeSlide)*slideHeight))) / (slideHeight))));
            
            if (factor > 0.4) {
                targetHeight = 10 + (30*((factor-0.4)/0.6));
            }
        }
        
        gsap.to($navProgress.get(0), { duration: 0.4, ease: 'power2.out', y: startY, height: targetHeight });
    };
    
    return {
        init,
        destroy
    };

};

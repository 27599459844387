import $ from '../core/Dom';
import VideoController from '../lib/VideoController';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const link = $el.find('[data-link]');
    const overlay = $el.find('[data-video-overlay]');
    const videoContainer = $el.find('[data-embed-wrap]');

    let controller;
    let videoInited = false;

    const init = () => {
        controller = new VideoController(onStateChange);
        $el.find('[data-link]').on('click', onClick);
    };

    const destroy = () => {
        $el.off('click');
        controller.destroy();
    };

    const onClick = e => {
        e.preventDefault();
        checkAndPlay();
    };

    const keyup = e => {
        const key = e.keyCode || e.which;
        if (key === 27) {
            controller.pause();
            //close();
        }
    };

    const onStateChange = status => {
        if (status === controller.PLAY && !$el.hasClass('is-playing')) {
            //play();
        } else if (status === controller.PAUSE && $el.hasClass('is-playing')) {
            //close();
        }
    };

    const checkAndPlay = () => {
        if (!videoInited) {
            videoInited = true;
            videoContainer.html(props.embedCode);
            controller.init(videoContainer.find('iframe'), true);
            play();
        } else {
            controller.play();
            play();
        }
    };

    const play = () => {
        document.addEventListener('keyup', keyup);

        gsap.set(videoContainer.get(0), { display: 'block', opacity: 0 });
        gsap.to(videoContainer.get(0), { duration: 0.3, opacity: 1 });
        gsap.to([link.get(0), overlay.get(0)], {
            duration: 0.3, opacity: 0, onComplete: () => {
                link.css({ display: 'none' });
                overlay.css({ display: 'none' });
            }
        });

        $el.addClass('is-playing');
    };

    const close = () => {
        document.removeEventListener('keyup', keyup);

        link.css({ display: '', opacity: 0 });
        overlay.css({ display: '', opacity: 0 });
        gsap.to(videoContainer.get(0), { duration: 0.3, opacity: 0 });
        gsap.to([link.get(0), overlay.get(0)], { duration: 0.3, opacity: 1 });

        $el.removeClass('is-playing');
    };


    return {
        init,
        destroy
    };
};

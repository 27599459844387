import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import gsap from 'gsap';
import serialize from 'form-serialize';


export default el => {
    const $el = $(el);

    const $introWrapper = $el.find('[data-quiz-intro]');
    const $itemsWrapper = $el.find('[data-quiz-items-wrapper]');
    const $resultsWrapper = $el.find('[data-quiz-result-wrapper]');
    const $items = $el.find('[data-quiz-item]');
    const $form = $el.find('form');

    const $startBtn = $el.find('[data-start-quiz-button]');
    const $prevBtn = $el.find('[data-quiz-prev-button]');
    const $nextBtn = $el.find('[data-quiz-next-button]');

    let currentItem = 0;
    let boxWidth = 0;
    let boxOffsetX = 0;
    let quizActive = false;
    let usingKeys = false;

    const init = () => {
        Viewport.on('resize', onResize);
        $startBtn.on('click', onStartClick);
        $prevBtn.on('click', onPrevClick);
        $nextBtn.on('click', onNextClick);

        //debug
        //startQuiz();

        $form.on('change', 'input', onInputChange);
        document.addEventListener('keyup', onKeyUp);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        document.removeEventListener('keyup', onKeyUp);
    };
    
    const onKeyUp = () => {
        usingKeys = true;
    };

    const onResize = () => {
        const vw = Viewport.width;

        if (quizActive) {
            resetItems();
            positionItems(true);
        }
    };

    const onInputChange = e => {
        checkPrevNext();
        
        const $currentItem = $($items.get(currentItem));

        if (!usingKeys && $currentItem.find('input[type="radio"]:checked').length > 0) {
            next();
        }
    };

    const onStartClick = e => {
        e.preventDefault();
        startQuiz();
    };

    const onPrevClick = e => {
        e.preventDefault();
        prev();
    };

    const onNextClick = e => {
        e.preventDefault();
        next();
    };
    
    const prev = () => {
        if (currentItem > 0) {
            currentItem -= 1;
            positionItems(false);
        }
    };

    const next = () => {
        if (currentItem < $items.length - 1) {
            currentItem += 1;
            positionItems(false);
        } else if (currentItem === $items.length - 1) {
            showResult();
        }
    };

    const startQuiz = () => {
        $resultsWrapper.css({ display: 'none' });

        gsap.to($introWrapper.get(0), {
            duration: 0.4, ease: 'quart.in', y: -150, opacity: 0, onComplete: () => {
                $introWrapper.css({ display: 'none' });
                $itemsWrapper.css({ display: 'block' });
                resetItems();
                positionItems(true);

                gsap.from($itemsWrapper.get(0), { duration: 1, ease: 'quart.out', y: 150, opacity: 0 })
                quizActive = true;
            }
        });

    };

    const showResult = () => {
        $introWrapper.css({ display: 'none' });

        quizActive = false;

        gsap.to($itemsWrapper.get(0), {
            duration: 0.4, ease: 'quart.in', y: -150, opacity: 0, onComplete: () => {
                $itemsWrapper.css({ display: 'none' });
                $resultsWrapper.css({ display: 'block' });

                calculateResult();

                gsap.from($resultsWrapper.get(0), { duration: 1, ease: 'quart.out', y: 150, opacity: 0 })
            }
        });
    };

    const resetItems = () => {
        const $boxes = $itemsWrapper.find('[data-quiz-item-box]');
        gsap.set($boxes.nodes, { x: 0, rotate: 0, height: '' });
        $boxes.removeClass('active')
        

        const $box = $($boxes.get(0));
        boxWidth = $box.width();
        boxOffsetX = $box.offset().left;

        let tallestBox = 0;
        
        $boxes.each(box => { 
            const $box = $(box);
            
            if ($box.height() > tallestBox) {
                tallestBox = $box.height();
            }
        })
        
        $boxes.height(tallestBox);
    };

    const positionItems = immediate => {
        checkPrevNext();
        $items.each((item, i) => {
            positionItem(item, i, immediate)
        });
    };

    const positionItem = (item, itemIndex, immediate) => {
        const $box = $(item).find('[data-quiz-item-box]');

        if (itemIndex === currentItem) {
            gsap.to($box.get(0), { duration: immediate ? 0 : 0.8, ease: 'quint.out', x: 0, rotate: 0 });
        } else if (itemIndex > currentItem) {
            gsap.to($box.get(0), { duration: immediate ? 0 : 0.8, ease: 'quint.out', x: Viewport.width - boxOffsetX - getSideOffset(), rotate: gsap.utils.random(0, 5) * (itemIndex % 2 ? 1 : -1) });
        } else if (itemIndex < currentItem) {
            gsap.to($box.get(0), { duration: immediate ? 0 : 0.8, ease: 'quint.out', x: -boxOffsetX - boxWidth + getSideOffset('left'), rotate: gsap.utils.random(0, 5) * (itemIndex % 2 ? 1 : -1) });
        }

        if (itemIndex === currentItem) {
            $box.addClass('active');
        } else {
            $box.removeClass('active');
        }
        
        const distance = Math.abs(currentItem - itemIndex);
        $box.css({ filter: 'brightness(' + (1 - (Math.max(0, distance-1)*0.1)) + ')', zIndex: 10-distance });
    };

    const checkPrevNext = () => {
        $prevBtn.attr({ disabled: currentItem <= 0 ? '' : null });

        const $currentItem = $($items.get(currentItem));

        if ($currentItem.find('input[type="radio"]:checked').length > 0) {
            $nextBtn.attr({ disabled: null });
        } else {
            $nextBtn.attr({ disabled: '' });
        }
    };

    const calculateResult = () => {
        const resultData = serialize($form.get(0), true);
        const result = {};

        Object.keys(resultData).forEach(function(key) {
            const pointsData = JSON.parse(resultData[key]);

            pointsData.forEach(data => {
                result[data.resultIdentifier] = result[data.resultIdentifier] === undefined ? parseInt(data.points) : result[data.resultIdentifier] + parseInt(data.points);
            });
        });
        console.log(result);
        const resultId = Object.keys(result).reduce((a, b) => result[a] > result[b] ? a : b);

        $resultsWrapper.find('[data-quiz-result]').css({ display: 'none' });

        let $result = $resultsWrapper.find('[data-quiz-result="' + resultId + '"]');

        if ($result.length === 0) {
            $result = $($resultsWrapper.find('[data-quiz-result]').get(0));
            console.error('The result could not be found, picked the first!')
        }
        $result.css({ display: 'block' });
    };

    const getSideOffset = (direction) => {
        const bpSize = Viewport.breakpoint.size

        if (direction === 'left' && bpSize < 750) {
            return -30;
        }

        if (bpSize < 750) {
            return 30;
        } else if (bpSize < 980) {
            return 40;
        } else if (bpSize < 1420) {
            return 60;
        }

        return 90;
    };

    return {
        init,
        destroy
    };

};

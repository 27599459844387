import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $cta = $el.find('[data-footer-cta]');
    
    let tl = null;
    let resizeTimeout = null;

    const init = () => {
        initFooterCta();
        onResize();
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    const onResize = () => {
        clearTimeout(resizeTimeout);
        
        resizeTimeout = setTimeout(() => {
            reset();
            initFooterCta();
        }, 200);
    };
    
    const onScroll = () => {
        if (tl) {
            if (Viewport.scrollTop < $el.offset().top - Viewport.height) {
                return;
            }
            tl.pause(Viewport.scrollTop);
        }
    };
    
    const reset = () => {
        if (tl) {
            //lastTime = tl.time();
            tl.kill();
        }
        
        if ($cta.length > 0) {
            $cta.attr('style', null);
            $cta.find('[data-footer-cta-heading]').attr('style', null);
            $cta.find('[data-footer-cta-text]').attr('style', null);
        }
    };
    
    const initFooterCta = () => {
        if ($cta.length > 0) {
            reset();

            const ctaOffsetY = $cta.offset().top;

            const $ctaHeading = $cta.find('[data-footer-cta-heading]');
            const $ctaText = $cta.find('[data-footer-cta-text]');

            gsap.set($cta.get(0), { y: Viewport.breakpoint.size > 750 ? 180 : 80 });
            gsap.set($ctaHeading.get(0), { y: Viewport.breakpoint.size > 750 ? 50 : 20 });
            gsap.set($ctaText.get(0), { y: Viewport.breakpoint.size > 750 ? 50 : 20 });

            tl = gsap.timeline();
            tl.to($cta.get(0), { duration: Viewport.height * 0.9, y: 0, ease: 'sine.out' }, ctaOffsetY - Viewport.height);
            tl.to([$ctaHeading.get(0), $ctaText.get(0)], { duration: Viewport.height * 0.9, y: 0, ease: 'sine.out' }, ctaOffsetY - Viewport.height + (Viewport.height * 0.2));
            tl.paused();

            onScroll();
        }
    };

    return {
        init,
        destroy
    };
};

import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

import * as events from '../lib/events';

export default el => {
    const $el = $(el);
    const $body = $('body');
    const $bar = $el.find('[data-page-header-bar]');
    const $barBg = $el.find('[data-page-header-bar-bg]');
    const $bg = $el.find('[data-page-header-full-bg]');
    const $navPanels = $el.find('[data-nav-panels-wrapper]');
    const $languagePanel = $el.find('[data-language-panel]');
    const $burger = $el.find('[data-burger]');

    let menuOpen = false;
    let $menu = null;
    let focusedElement = null;
    let breakpoint = Viewport.breakpoint.size;

    let isHidden = false;
    let isLocked = false;
    let hiddenThreshold = 30;
    let currentScrollTop = Viewport.scrollTop;

    let openPanel = '';
    let languagePanelOpen = false;
    
    let wasTransparent = false;

    const isSmall = bp => (bp || Viewport.breakpoint.size) < 1240;

    const init = () => {
        const navId = $burger.attr('href');
        $menu = $el.find(navId);
        
        $bar.removeClass('js:opacity-0');
        $barBg.removeClass('js:opacity-0');
        
        if ($el.hasClass('is-transparent')) {
            wasTransparent = true;
        }
        
        $burger
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    toggleMenu();
                }
            })
            .on('click', e => {
                e.preventDefault();
                toggleMenu();
            })
            .get(0)
            .removeAttribute('href');

        Dispatch.on(events.CLOSE_MOBILE_MENU, onCloseMobileMenu);

        $navPanels.removeClass('bg-white', 'bg-opacity-80', 'backdrop-blur-[10px]');
        $navPanels.find('[data-nav-panel]').css({ display: 'none' });
        $navPanels.find('a').on('click', onNavPanelLinkClick);

        $el.on('click', '[data-panel-trigger]', onPanelTrigger);
        $bg.on('click', onBgClick);

        $languagePanel.css({ display: 'none' });
        $el.on('click', '[data-language-toggle]', onLanguageTrigger);
        
        $languagePanel.find('a').on('click', () => {
            hideLanguagePanel();
        });

        $('body').on('keyup', onBodyKeyUp);

        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $el.on('focusin', 'a,button', onInnerFocus);

        onResize();

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                openMenu(false);
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }

            if (window.scrollY > hiddenThreshold) {
                hide(true);
            }
        });
    };

    const destroy = () => {
        $('body').off('keyup', onBodyKeyUp);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $el.off('click focusin');
        $bg.off('click');
    };

    const onBgClick = () => {
        if (openPanel === '') {
            return;
        }

        hideCurrentPanel();
    };


    /**
     * Mobile menu handling
     */
    const closeMenu = (tween = true) => {
        if (!menuOpen) {
            return;
        }

        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        $burger.attr('aria-expanded', 'false');
        menuOpen = false;
        $el.removeClass('js-mobile-menu-open');
    };

    const openMenu = (tween = true) => {
        if (menuOpen) {
            return;
        }

        menuOpen = true;
        $burger.attr('aria-expanded', 'true');

        focusedElement = document.activeElement || null;
        Viewport.lockTabbing($menu.get(0), $menu.find('[data-close-btn]').get(0));

        $el.addClass('js-mobile-menu-open');

        Dispatch.emit(events.OPEN_MOBILE_MENU, { tween });
    };

    const onCloseMobileMenu = () => {
        closeMenu();
    };

    const toggleMenu = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    /**
     * Scroll behaviour
     */
    const hide = (force) => {
        if (isHidden) {
            return;
        }
        isHidden = true;

        if (force) {
            $el.addClass('is-forced');
            
            setTimeout(() => {
                $el.removeClass('is-forced');
            }, 600)
        }
        
        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const lock = () => {
        if (isLocked) {
            return;
        }
        isLocked = true;
        $el.addClass('is-locked');
    };

    const unlock = () => {
        if (!isLocked) {
            return;
        }
        isLocked = false;
        $el.removeClass('is-locked');
    };

    const onBreakpoint = () => {
        const wasSmall = isSmall(breakpoint);

        if (wasSmall !== isSmall()) {
            closeMenu(false);
        }

        if (!wasSmall && isSmall() && openPanel !== '') {
            hideCurrentPanel();
        }

        breakpoint = Viewport.breakpoint.size;
    };

    const onBodyKeyUp = e => {
        if (openPanel === '') {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            hideCurrentPanel();
        }
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }
        
        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }

        if (wasTransparent) {
            if (scrollTop < hiddenThreshold && !$el.hasClass('is-transparent')) {
                $el.addClass('is-transparent');
            } else if (scrollTop >= hiddenThreshold && $el.hasClass('is-transparent')) {
                $el.removeClass('is-transparent');
            }
        }

        currentScrollTop = scrollTop;
        
        if (languagePanelOpen) {
            hideLanguagePanel();
        }
    };

    const onInnerFocus = e => {
        show();
    };

    const getThreshold = () => {
        if ($body.find('[data-frontpage-hero-slider]').length > 0) {
            return $body.find('[data-frontpage-hero-slider]').height() - Viewport.height + 80;
        }
        
        if (breakpoint < 1240) {
            return 64;
        } else if (breakpoint < 1420) {
            return 80;
        }

        return 110;
    };

    const onResize = () => {
        hiddenThreshold = getThreshold();

        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    /**
     * Desktop menu handling
     */

    const onPanelTrigger = e => {
        e.preventDefault();

        const $trigger = $(e.triggerTarget);
        const href = $trigger.attr('href');

        if (openPanel === href) {
            hideCurrentPanel(false);
        } else {
            showPanel(href);
        }
    };
    
    const onNavPanelLinkClick = e => {
        if (openPanel !== '') {
            hideCurrentPanel(false);
        }
    };

    const hideCurrentPanel = (willShow, nextIndex) => {
        if (openPanel === '') {
            return;
        }

        const $panel = $el.find(openPanel);
        const panelIndex = $panel.data('nav-panel');

        if ($panel.length === 0) {
            return;
        }

        let moveTo = 0;

        if (nextIndex !== 'undefined') {
            if (nextIndex > panelIndex) {
                moveTo = -50;
            } else if (nextIndex < panelIndex) {
                moveTo = 50;
            }
        }

        gsap.to($panel.get(0), {
            duration: willShow ? 0.3 : 0.05, opacity: 0, x: moveTo, ease: willShow ? 'quart.in' : 'linear', onComplete: () => {
                $panel.css({ display: 'none' });
            }
        });

        openPanel = '';
        unlock();

        if (!willShow) {
            const startHeight = $barBg.height();
            $barBg.css({ height: '' });

            gsap.from($barBg.get(0), { delay: 0, duration: 0.5, height: startHeight, ease: 'quart.out' });

            gsap.to($bg.get(0), {
                delay: 0, duration: 0.3, opacity: 0, onComplete: () => {
                    $bg.css({ display: 'none' });
                }
            });

            setOpenPanelToggle(null);
        }
    };

    const showPanel = href => {
        const $panel = $el.find(href);
        const hasOpenPanel = openPanel !== '';
        let moveTo = 0;

        if ($panel.length === 0) {
            return;
        }

        if (hasOpenPanel) {
            const $openPanel = $el.find(openPanel);
            const oldPanelIndex = $openPanel.data('nav-panel');
            const newPanelIndex = $panel.data('nav-panel');

            if (newPanelIndex > oldPanelIndex) {
                moveTo = 50;
            } else if (newPanelIndex < oldPanelIndex) {
                moveTo = -70;
            }

            hideCurrentPanel(true, $panel.data('nav-panel'));
        }

        setOpenPanelToggle(href);

        if (languagePanelOpen) {
            hideLanguagePanel();
        }

        gsap.set($panel.get(0), { display: 'block', opacity: 0, x: moveTo })
        gsap.set($bg.get(0), { display: 'block' })

        openPanel = href;
        lock();

        if (!hasOpenPanel) {
            gsap.to($bg.get(0), { duration: 0.2, opacity: 1 });
        }

        gsap.to($barBg.get(0), { duration: 1, height: $panel.height(), ease: 'quint.out' });

        gsap.to($panel.get(0), { delay: hasOpenPanel ? 0.4 : 0.3, duration: 0.7, opacity: 1, x: 0, ease: 'quart.out' });
    };

    const setOpenPanelToggle = href => {
        $el.find('[data-panel-trigger]').removeClass('is-selected');

        if (href !== null) {
            $el.find('[data-panel-trigger][href="' + href + '"]').addClass('is-selected');
        }
    };

    const onLanguageTrigger = e => {
        e.preventDefault();

        if (openPanel !== '') {
            hideCurrentPanel();
        }

        if (languagePanelOpen) {
            hideLanguagePanel();
        } else {
            showLanguagePanel();
        }
    };

    const showLanguagePanel = () => {
        gsap.set($languagePanel.get(0), { display: 'block', opacity: 0, y: 20 });
        //gsap.set($languagePanel.find('a').nodes, { opacity: 0, y: -20 });
        //gsap.to($languagePanel.find('a').nodes, { opacity: 1, y: 0, duration: 0.5, stagger: 0.05, ease: 'quart.out' });
        
        gsap.to($languagePanel.get(0), { opacity: 1, y: 0, duration: 0.5, ease: 'quart.out' });

        languagePanelOpen = true;
        $el.find('[data-language-toggle]').addClass('is-selected');
    };

    const hideLanguagePanel = () => {
        /*
        gsap.to($languagePanel.find('a').nodes, {
            opacity: 0, y: -20, duration: 0.15, stagger: -0.05, ease: 'sine.in', onComplete: () => {
                $languagePanel.css({ display: 'none' });
            }
        });
         */
        
        gsap.to($languagePanel.get(0), { opacity: 0, y: 20, duration: 0.15, ease: 'sine.in', onComplete: () => {
                $languagePanel.css({ display: 'none' });
            }
        });


        languagePanelOpen = false;
        $el.find('[data-language-toggle]').removeClass('is-selected');
    };

    return {
        init,
        destroy
    };

};

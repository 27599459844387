import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from "../lib/async-bundles";

export default (el, props) => {
    const SLIDER_SELECTOR = '[data-slides-wrapper]';
    const SLIDE_SELECTOR = '[data-slide-item]';

    const $el = $(el);
    const { disableBreakpoint } = props;
    
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);

    let Flickity;
    let slideshow;

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            
            if (Viewport.breakpoint.size < disableBreakpoint) {
                initFlickity();
            }
            
            Viewport.on('resize', onResize);
        });
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
    };
    
    const initFlickity = () => {
        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            height: `${$slider.height()}px`
        });
        
        $slides.each(item => {
            const $item = $(item);
            $item.css({ height: $item.height(), display: 'block' });
        });
        
        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: false,
            freeScroll: true,
            contain: true,
            accessibility: true
        });
    };
    
    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        
        slideshow.destroy();
        slideshow = null;

        $slider.attr('style', '');
        $slides.attr('style', '');
        
        $el.removeClass('js-has-flickity');
    };

    const onResize = () => {
        if (Viewport.breakpoint.size < disableBreakpoint) {
            initFlickity();
        } else {
            destroyFlickity();
        }
    };
    
    return {
        init,
        destroy
    };

};

import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const $el = $(el);
    const $elements = $el.find('[data-animation-element]');
    const $mediaElements = $el.find('[data-animated-media-element]');

    const init = () => {
        gsap.set(el, { y: Viewport.breakpoint.size > 750 ? 70 : 30, opacity: 0 });
        gsap.set($elements.nodes, { y: Viewport.breakpoint.size > 750 ? 50 : 20, opacity: 0 });
        gsap.set($mediaElements.nodes, { scale: 1.1, opacity: 0 });
        
        gsap.to(el, { 
            scrollTrigger: {
                trigger: el,
                start: 'top top+=85%',
                toggleActions: "play none none reverse",
            },
            duration: 0.8, 
            ease: 'quart.out', 
            y: 0, 
            opacity: 1
        });
        
        gsap.to($elements.nodes, { 
            scrollTrigger: {
                trigger: el,
                start: "top top+=85%",
                toggleActions: "play none none reverse",
            },
            duration: 1, 
            delay: 0.5,
            stagger: 0.1, 
            ease: 'quart.out', 
            y: 0, 
            opacity: 1 
        });
        
        gsap.to($mediaElements.nodes, { 
            scrollTrigger: {
                trigger: el,
                start: "top top+=85%",
                toggleActions: "play none none reverse",
            },
            duration: 2, 
            stagger: 0.1, 
            ease: 'quart.out', 
            scale: 1, 
            opacity: 1 
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};

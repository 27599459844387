import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {
    const $el = $(el);
    const $elements = $el.find('[data-animation-element]');
    const $mediaElements = $el.find('[data-animated-media-element]');
    const $logo = $el.find('[data-core-plus-logo]');
    const $logoInner = $el.find('[data-core-plus-logo-inner]');
    const $nameElements = $el.find('[data-core-plus-name] span');
    const $pageHeaderBarBg = $('[data-page-header-bar-bg]');

    const init = () => {
        createAnimations();
        
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        gsap.killTweensOf(el);
        gsap.killTweensOf($logo.get(0));
        gsap.killTweensOf($logoInner.get(0));
        gsap.killTweensOf($elements.nodes);
        gsap.killTweensOf($mediaElements.nodes);
        gsap.killTweensOf($nameElements.nodes);
    };
    
    const onResize = () => {
        createScrollAnimation();
    };

    const createAnimations = () => {
        gsap.set(el, { y: Viewport.breakpoint.size > 750 ? 70 : 30, opacity: 0 });
        gsap.set($elements.nodes, { y: Viewport.breakpoint.size > 750 ? 50 : 20, opacity: 0 });
        gsap.set($mediaElements.nodes, { scale: 1.1, opacity: 0 });
        gsap.set($logo.nodes, { rotate: -90 });
        gsap.set($logoInner.nodes, { rotate: 0 });
        gsap.set($nameElements.nodes, { y: Viewport.breakpoint.size > 750 ? 30 : 10, opacity: 0 });

        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: 'top top+=85%',
                toggleActions: 'play none none reverse'
            },
            duration: 0.8,
            ease: 'quart.out',
            y: 0,
            opacity: 1,
            onComplete: () => {
                createScrollAnimation();
            }
        });

        gsap.to($logo.get(0), {
            scrollTrigger: {
                trigger: el,
                start: 'top top+=85%',
                toggleActions: 'play none none reverse'
            },
            duration: 0.8,
            ease: 'quart.out',
            rotate: 0
        });

        gsap.to($elements.nodes, {
            scrollTrigger: {
                trigger: el,
                start: 'top top+=85%',
                toggleActions: 'play none none reverse'
            },
            duration: 1,
            delay: 0.5,
            stagger: 0.1,
            ease: 'quart.out',
            y: 0,
            opacity: 1
        });

        gsap.to($mediaElements.nodes, {
            scrollTrigger: {
                trigger: el,
                start: 'top top+=85%',
                toggleActions: 'play none none reverse'
            },
            duration: 2,
            stagger: 0.1,
            ease: 'quart.out',
            scale: 1,
            opacity: 1
        });

        gsap.to($nameElements.nodes, {
            scrollTrigger: {
                trigger: el,
                start: 'top top+=85%',
                toggleActions: 'play none none reverse'
            },
            duration: 1,
            delay: 0.1,
            stagger: 0.05,
            ease: 'quart.out',
            y: 0,
            opacity: 1
        });
    };

    const createScrollAnimation = () => {
        gsap.killTweensOf($logoInner.get(0));
        gsap.to($logoInner.get(0), {
            scrollTrigger: {
                trigger: el,
                scrub: 0.4,
                start: 'top top+='+getLogoStartOffset(),
                end: '+=80',
                markers: false
            },
            rotate: 135
        });


    };

    const getLogoStartOffset = () => {
        return $pageHeaderBarBg.height();
    };

    return {
        init,
        destroy
    };
};

import $ from '../core/Dom';

export default el => {
    const $el = $(el);
    const $toggleMenuLinks = $el.find('[data-menu-link="2"]');
    const $toggleMenuItems = $el.find('[data-menu-item="2"]');

    const init = () => {
        $toggleMenuLinks.on('click', onToggleLinkClick);
    };

    const destroy = () => {
        $toggleMenuLinks.off('click', onToggleLinkClick);
    };
    
    const onToggleLinkClick = e => {
        console.log('onToggleLinkClick');
        e.preventDefault();
        const $parent = $(e.currentTarget.parentNode);
        
        if ($parent.hasClass('selected-branch')) {
            $parent.removeClass('selected-branch');
        } else {
            $toggleMenuItems.removeClass('selected-branch');
            $parent.addClass('selected-branch');
        }
    };

    return {
        init,
        destroy
    };

};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import * as events from '../lib/events';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollToPlugin, SplitText, Draggable);

export default el => {
    const $el = $(el);
    const $textSlides = $el.find('[data-slide-text-panel]');
    const $mediaSlides = $el.find('[data-slide-media-panel]');

    const $scrollPanels = $el.find('[data-scroll-panel]');
    
    const $nav = $el.find('[data-slides-nav]');
    const $navProgress = $nav.find('[data-slides-nav-progress]');

    const $scrollStartBtn = $el.find('[data-scroll-start-btn]');
    const $videoProgress = $el.find('[data-video-progress]');
    const $videoProgressDragger = $el.find('[data-video-progress-dragger]');
    const $videoProgressLine = $el.find('[data-video-progress-line]');

    let panelObserver = null;
    let moduleObserver;

    let activePanel;
    let activeIndex;
    let progressLineWidth = 0;
    let draggable;
    let isDragging = false;
    let currentVideoDuration = 0;
    let hasPreloaded = false;
    let isInited = false;
    let showTimeout = null;
    let slideElements = [];
    let hasShownProgress = false;

    const init = () => {
        $scrollStartBtn.on('click', e => {
            onScrollButtonClick($(e.triggerTarget));
        });
        
        $nav.on('click', 'button', e => {
            onScrollToSlide($(e.triggerTarget));
        });

        initSlides();
        gsap.set($scrollStartBtn.get(0), { display: 'block', opacity: 0 });

        panelObserver = new IntersectionObserver(onPanelObserve, {
            threshold: [0, 0.25, 0.5, 0.75, 1]
        });

        $el.find('[data-scroll-panel]').each(panel => {
            panelObserver.observe(panel);
        });

        moduleObserver = new IntersectionObserver(onModuleObserve, {});
        moduleObserver.observe(el);

        initDragger();

        Dispatch.on(events.FRONTPAGE_VIDEO_TICK, onVideoTick);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        $scrollStartBtn.off('click');
    };

    const onResize = () => {

    };

    const onScroll = () => {
        updateNavProgress(true);
    };

    const reset = () => {
        slideElements.forEach(item => {
            item.splitResult.revert();
        });

        slideElements = [];
        isInited = false;
    };

    const initSlides = () => {
        reset();

        $mediaSlides.css({ display: 'block', opacity: 0 });
        $textSlides.css({ display: 'block' });

        $textSlides.each((slide, index) => {
            const $slide = $(slide);

            const oldDisplay = $slide.css('display');
            $slide.css('display', 'block');

            const $heading = $slide.find('[data-slide-panel-heading]');
            const $text = $slide.find('[data-slide-panel-text]');
            const headingSplit = new SplitText($heading.get(0), { type: 'lines' });

            slideElements.push({
                splitResult: headingSplit,
                $text: $text,
            });

            gsap.set($text.nodes, { opacity: 0 });
            gsap.set(headingSplit.lines, { opacity: 0 });

            $slide.css('display', oldDisplay);
        });

        isInited = true;
    };

    const onScrollButtonClick = $button => {
        let targetY = $el.height();
        let duration = 0.8;
        
        if (activeIndex < $scrollPanels.length - 1) {
            const $panel = $scrollPanels.eq(activeIndex + 1);
            targetY = $panel.offset().top - (Viewport.height/2) + 2;
            duration = 0.3;
        }
        
        gsap.to(window, { duration: duration, ease: 'quart.out', scrollTo: { y: targetY } });
    };

    const onScrollToSlide = $button => {
        const $panel = $scrollPanels.eq($button.data('slide-goto-index'));
        gsap.to(window, { duration: 0.3, ease: 'sine.out', scrollTo: { y: $panel.offset().top - (Viewport.height/2) + 2 } });
    };
    
    const onPanelObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0.5 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }
        intersecting = intersecting.sort((a, b) => parseFloat(b.intersectionRatio) - parseFloat(a.intersectionRatio)).map(({ target }) => target);

        setActivePanel(intersecting.shift());
    };

    const setActivePanel = panel => {
        const oldIndex = activePanel ? parseInt(activePanel.dataset.scrollPanel) : null;
        const index = parseInt(panel.dataset.scrollPanel);

        if (oldIndex === index) {
            return;
        }

        activePanel = panel;
        activeIndex = index;

        if (oldIndex !== null) {
            hideSlide(oldIndex, index > oldIndex);
        }

        showSlide(index, oldIndex !== null ? index > oldIndex : true);

        gsap.to($scrollStartBtn.get(0), { duration: 0.6, opacity: 1 });
        
        if (index === 0) {

            gsap.set($videoProgress.get(0), { display: 'block', opacity: 0 });
            gsap.to($videoProgress.get(0), {
                duration: 0.2, opacity: 0, onComplete: () => {
                    gsap.set($videoProgress.get(0), { display: 'none' });
                }
            });
        } else if (index > 0 && (oldIndex === 0 || oldIndex === null)) {
            /*
            gsap.to($scrollStartBtn.get(0), {
                duration: 0.2, opacity: 0, onComplete: () => {
                    gsap.set($scrollStartBtn.get(0), { display: 'none' });
                }
            });
            *
             */

            gsap.set($videoProgress.get(0), { display: 'block', opacity: 0 });
            gsap.to($videoProgress.get(0), { duration: 0.8, opacity: 1 });
            
            if (!hasShownProgress) {
                setTimeout(() => {
                    $videoProgressDragger.addClass('is-active');
                }, 1500);
            }

            progressLineWidth = $videoProgressLine.width();
        }
        
        updateNavProgress(false);
    };

    const hideSlide = (index, forward) => {
        //console.log('hideSlide', index, forward);

        const $mediaSlide = $mediaSlides.eq(index);

        gsap.to($mediaSlide.get(0), { duration: 1, opacity: 0 });

        const elements = slideElements[index];
        const lines = elements.splitResult.lines;
        const $text = elements.$text;

        let animNodes = lines.concat($text.nodes);

        if (!forward) {
            animNodes = animNodes.reverse();
        }

        gsap.killTweensOf(animNodes);
        gsap.to(animNodes, { duration: 0.4, stagger: 0.05, y: forward ? -60 : 60, ease: 'sine.in', overwrite: true });
        gsap.to(animNodes, {
            duration: 0.4, stagger: 0.05, opacity: 0, ease: 'none', onComplete: () => {
                Dispatch.emit(events.DEACTIVATE_FRONTPAGE_VIDEO, { index })
            }
        });
    };

    const showSlide = (index, forward) => {
        //console.log('showSlide', index, forward);

        Dispatch.emit(events.ACTIVATE_FRONTPAGE_VIDEO, { index })
        gsap.to($videoProgressDragger.get(0), { x: 0, duration: 0.3, ease: 'sine.inOut' });
        
        const $mediaSlide = $mediaSlides.eq(index);
        gsap.to($mediaSlide.get(0), { duration: 1, opacity: 1 });

        const elements = slideElements[index];
        const lines = elements.splitResult.lines;
        const $text = elements.$text;

        let animNodes = lines.concat($text.nodes);

        if (!forward) {
            animNodes = animNodes.reverse();
        }

        gsap.killTweensOf(animNodes);
        gsap.set(animNodes, { opacity: 0, y: forward ? 60 : -60 });

        clearTimeout(showTimeout);
        showTimeout = setTimeout(() => {
            gsap.to(animNodes, { duration: 1, stagger: 0.075, y: 0, ease: 'quint.out', overwrite: true });
            gsap.to(animNodes, { duration: 1, stagger: 0.075, opacity: 1, ease: 'none' });
        }, 1000);
    };

    const onModuleObserve = entries => {
        let intersecting = [];
        entries.forEach(entry => {
            const {
                target,
                isIntersecting,
                intersectionRatio
            } = entry;
            if (!isIntersecting || (intersectionRatio < 0 && !!activePanel)) {
                return;
            }
            intersecting.push({
                target,
                intersectionRatio
            });
        });
        if (!intersecting.length) {
            return;
        }

        if (!hasPreloaded) {
            $el.find('img.lazyload').addClass('lazypreload');
            hasPreloaded = true;
            moduleObserver.disconnect();
        }
    };

    const onVideoTick = (key, data) => {
        const { index, currentTime, duration } = data;

        if (index === activeIndex && !isDragging) {
            if (currentVideoDuration !== duration) {
                currentVideoDuration = duration;
            }

            gsap.set($videoProgressDragger.get(0), { x: (currentTime / duration) * progressLineWidth });
        }
    };
    
    const startDrag = () => {
        const $mediaSlide = $mediaSlides.eq(activeIndex);
        const $textSlide = $textSlides.eq(activeIndex);
        const $overlay = $mediaSlide.find('[data-slide-media-panel-overlay]');
        const $content = $textSlide.find('[data-slide-text-panel-content]');
        
        gsap.to($overlay.get(0), { duration: 0.5, opacity: 0 });
        gsap.to($content.get(0), { duration: 0.5, opacity: 0.4, scale: 0.9, ease: 'quint.out' });
    };

    const endDrag = () => {
        const $mediaSlide = $mediaSlides.eq(activeIndex);
        const $textSlide = $textSlides.eq(activeIndex);
        const $overlay = $mediaSlide.find('[data-slide-media-panel-overlay]');
        const $content = $textSlide.find('[data-slide-text-panel-content]');

        gsap.to($overlay.get(0), { duration: 0.5, opacity: 1 });
        gsap.to($content.get(0), { duration: 0.5, opacity: 1, scale:1, ease: 'quint.out' });
    };

    const initDragger = () => {
        draggable = Draggable.create($videoProgressDragger.get(0), {
            type: 'x',
            trigger: $videoProgressDragger.get(0),
            bounds: $videoProgressLine.get(0),
            onPress: function(e) {
                isDragging = true;
                startDrag();
                Dispatch.emit(events.FRONTPAGE_VIDEO_START_DRAG, { index: activeIndex });
            },
            onDrag: function() {
                const newTime = (this.x / this.maxX) * currentVideoDuration;
                Dispatch.emit(events.FRONTPAGE_VIDEO_SKIPTO, { index: activeIndex, newTime });
            },
            onRelease: function(e) {
                isDragging = false;
                endDrag();
                Dispatch.emit(events.FRONTPAGE_VIDEO_END_DRAG, { index: activeIndex });
            }
        });

        /*
        $videoProgressDragger.on('mousedown touchstart', () => {
            $videoProgressDragger.addClass('is-active');
        });
        $videoProgressDragger.on('mouseup touchend', () => {
            $videoProgressDragger.removeClass('is-active');
        });
        *
         */
    };
    
    const updateNavProgress = onlyProgress => {
        const $buttons = $nav.find('button');
        
        if (!onlyProgress) {
            $buttons.removeClass('selected');
            $buttons.eq(activeIndex).addClass('selected');
        }

        const startY = $buttons.eq(activeIndex).position().top;
        let targetHeight = 10;
        
        if (activeIndex < $buttons.length-1) {
            const $activePanel = $scrollPanels.eq(activeIndex);
            const $nextPanel = $scrollPanels.eq(activeIndex+1);

            const factor = Math.min(1, (Math.max(0, (((Viewport.scrollTop + Viewport.height/2)-$activePanel.offset().top)) / ($nextPanel.offset().top - $activePanel.offset().top))));
            
            if (factor > 0.4) {
                targetHeight = 10 + (30*((factor-0.4)/0.6));
            }
        } 
        
        gsap.to($navProgress.get(0), { duration: 0.4, ease: 'power2.out', y: startY, height: targetHeight });
    };

    return {
        init,
        destroy
    };

};
